@font-face {
    font-family: "CoinbitsCircular";
    src: url("CoinbitsCircularB-Regular.woff2") format("woff2"),
        url("CoinbitsCircularB-Regular.woff") format("woff"),
        url("CoinbitsCircularB-Regular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "CoinbitsCircular";
    src: url("CoinbitsCircularB-Medium.woff2") format("woff2"),
        url("CoinbitsCircularB-Medium.woff") format("woff"),
        url("CoinbitsCircularB-Medium.otf") format("otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CoinbitsCircular";
    src: url("CoinbitsCircularB-Medium.woff2") format("woff2"),
        url("CoinbitsCircularB-Medium.woff") format("woff"),
        url("CoinbitsCircularB-Medium.otf") format("otf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "CoinbitsCircular";
    src: url("CoinbitsCircularB-Medium.woff2") format("woff2"),
        url("CoinbitsCircularB-Medium.woff") format("woff"),
        url("CoinbitsCircularB-Medium.otf") format("otf");
    font-weight: 700;
    font-style: normal;
}