@import "./fonts/coinbits_circular/stylesheet.css";

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-font-variant-ligatures: none;
    -webkit-text-size-adjust: 100%;
    background-color: var(--colors-cbBackground);
    font-family: "CoinbitsCircular";
    font-size: 100%;
    font-size: 14px;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

:root {
    --green: #03a678;
    --dark-gray: #434e5a;
    --gray: #7f8fa4;
    --light-gray: #f4f7f9;
    --border: #ebecef;
    --black: #24262b;
    --light-blue: #f8fafd;
    --black-2: #24262b;
    --dark-blue: #1e3054;
    --blue: #1872ec;
    --mid-gray: #f8f9fa;
    --dark-green: #03a678;
    --med-gray: #999;
    --l-gray: #bbc0c5;
}

.input {
    font-family: "CoinbitsCircular";
}

.uppercase {
    letter-spacing: initial;
}

.card-shadow {
    box-shadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)";
}

/* hacky -- used for tooltips */
.mw-20 {
    max-width: 20rem;
}